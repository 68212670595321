import { useAuth } from "@msc-insure/use-auth";
import { StaticImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";
import { useForm } from "react-hook-form"
import React, { useState, useContext } from "react";
import { Link, Alert, Label, Input, Button, Box, Flex, Heading, Spinner, Text } from 'theme-ui'
import { SiteContext } from "../root"

const MagicLinkForm = () => {
  const { sendMagicLink } = useAuth()
  const { product } = useContext(SiteContext)
  const [error, setError] = useState()
  const { handleSubmit, formState, register } = useForm()
  const { isSubmitting, isSubmitted } = formState

  const onSubmit = async (values) => {
    const { email } = values
    try {
      await sendMagicLink(email, "MSC")
      setError(null)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      <Box as="form" sx={{ maxWidth: "40ch" }} onSubmit={handleSubmit(onSubmit)}>
        <Label htmlFor="email">Deine Email</Label>
        <Input {...register("email")} placeholder="email@beispiel.at" sx={{ fontSize: 3 }} />
        <Flex sx={{ marginTop: 3, alignItems: "center" }}>
          <Button disabled={isSubmitting} type="submit" sx={{ fontSize: 3 }}>Link Senden</Button>
          {isSubmitting ? <Spinner size={32} sx={{ marginLeft: 3 }} /> : null}
        </Flex>
      </Box>
      {isSubmitted && error && (
        <Alert sx={{ marginTop: 5, maxWidth: "60ch" }} variant="warning">
          <Text>
            Leider ist ein Problem aufgetreten. Vergewissere Dich das die Email stimmt und versuche es vielleicht nochmal oder
            schreib uns auf <Link href={`mailto:${product.email}`}>{product.email}</Link>.
          </Text>
        </Alert>
      )}
      {isSubmitted && !error && (
        <Alert sx={{ marginTop: 5, maxWidth: "60ch" }} variant="info">
          <Text>
            <span role="img" aria-label="celebrate emoji">
              🎉
            </span>{" "}
            Eine Email ist unterwegs zu Dir. Du kannst dieses Fenster jetzt schliessen.
          </Text>
        </Alert>
      )}
    </>
  )
}

function LoginPage() {
  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Box variant="layout.container">
          <Flex sx={{ mb: 5, alignItems: "center" }}>
            <GatsbyLink style={{ display: "inline-flex" }} to="/app">
              <StaticImage
                layout="constrained"
                placeholder="tracedSVG"
                width={48}
                alt="Karl"
                src="../../images/icon-round.png"
              />
            </GatsbyLink>
            <Text
              sx={{
                pl: 3,
                fontSize: 4,
                lineHeight: 1,
              }}
            >
              Partner
            </Text>
          </Flex>
          <Box sx={{ maxWidth: "48ch" }}>
            <Heading variant="styles.h1" color="primary" mb={4}>
              Anmeldung zum Partner Portal
            </Heading>
            <Text sx={{ fontSize: [2, 3] }}>
              Damit es weiter geht musst Du Dich noch anmelden. Hier geht es auch ohne Passwort. Zum Beispiel wenn Du es
              vergessen hast.
            </Text>
          </Box>
          <Box my={4}>
            <MagicLinkForm />
          </Box>
          <Link as={GatsbyLink} to="/login/password">
            Mit Passwort anmelden?
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default LoginPage;
